@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

:root {
  --toastify-color-light: #303030;
}

body {
  margin: 0;
  font-family: "LTSuperior", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Toastify {
  --toastify-color-light: #303030;
}

@font-face {
  font-family: "LT Superior";
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/LT Superior font/LTSuperior-Black.otf") format("otf");
}

@font-face {
  font-family: "LT Superior";
  font-style: normal;
  font-weight: 800;
  src: url("../fonts/LT Superior font/LTSuperior-ExtraBold.otf") format("otf");
}

@font-face {
  font-family: "LT Superior";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/LT Superior font/LTSuperior-Bold.otf") format("otf");
}

@font-face {
  font-family: "LT Superior";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/LT Superior font/LTSuperior-SemiBold.otf") format("otf");
}

@font-face {
  font-family: "LT Superior";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/LT Superior font/LTSuperior-Medium.otf") format("otf");
}

@font-face {
  font-family: "LT Superior";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/LT Superior font/LTSuperior-Regular.otf") format("otf");
}

@font-face {
  font-family: "LT Superior";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/LT Superior font/LTSuperior-Light.otf") format("otf");
}

@font-face {
  font-family: "LT Superior";
  font-style: normal;
  font-weight: 200;
  src: url("../fonts/LT Superior font/LTSuperior-ExtraLight.otf") format("otf");
}

@font-face {
  font-family: "LT Superior";
  font-style: normal;
  font-weight: 100;
  src: url("../fonts/LT Superior font/LTSuperior-Thin.otf") format("otf");
}
