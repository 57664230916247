/* Variables declaration */
/* prettier-ignore */
.rdp-root {
    --rdp-accent-color: #edff8c; /* The accent color used for selected days and UI elements. */
    --rdp-nav-color: #303030; /* The color of the navigation buttons. */
    --rdp-accent-background-color: #f0f0ff; /* The accent background color used for selected days and UI elements. */
    --rdp-font-family: "LTSuperior", sans-serif; /* The font family used by the calendar. Note that `inherit`does not work here. */
  
    --rdp-day-font: inherit; /* The font used for the day cells. */
    --rdp-day-height: 2.75rem; /* The height of the day cells. */
    --rdp-day-width: 2.75rem; /* The width of the day cells. */
    
    --rdp-day_button-border-radius: 100%; /* The border radius of the day cells. */
    --rdp-day_button-border: 2px solid transparent; /* The border of the day cells. */
    --rdp-day_button-height: var(--rdp-day-width); /* The height of the day cells. */
    --rdp-day_button-width: var(--rdp-day-height); /* The width of the day cells. */
    
    --rdp-selected-border: 2px solid var(--rdp-accent-color); /* The border of the selected days. */
    --rdp-selected-font: bold large var(--rdp-font-family); /* The font of the selected days. */
    --rdp-disabled-opacity: 0.2; /* The opacity of the disabled days. */
    --rdp-outside-opacity: 0.75; /* The opacity of the days outside the current month. */
    --rdp-today-color: var(--rdp-accent-color); /* The color of the today's date. */
    
    --rdp-dropdown-gap: 0.5rem;/* The gap between the dropdowns used in the month captons. */
    
    --rdp-month_caption-font: bold larger var(--rdp-font-family); /* The font of the month caption. */
    --rdp-months-gap: 2rem; /* The gap between the months in the multi-month view. */
    
    --rdp-nav_button-disabled-opacity: 0.5; /* The opacity of the disabled navigation buttons. */
    --rdp-nav_button-height: 2.25rem; /* The height of the navigation buttons. */
    --rdp-nav_button-width: 2.25rem; /* The width of the navigation buttons. */
    --rdp-nav-height: 1.5rem; /* The height of the navigation bar. */
    
    --rdp-range_middle-background-color: var(--rdp-accent-background-color); /* The color of the background for days in the middle of a range. */
    --rdp-range_middle-font: normal medium var(--rdp-font-family); /* The font for days in the middle of a range. */
    --rdp-range_middle-foreground-color: white; /* The font for days in the middle of a range. */
    --rdp-range_middle-color: inherit;/* The color of the range text. */
    
    --rdp-range_start-color: white; /* The color of the range text. */
    --rdp-range_start-background: linear-gradient(var(--rdp-gradient-direction), transparent 50%, var(--rdp-range_middle-background-color) 50%); /* Used for the background of the start of the selected range. */
    --rdp-range_start-date-background-color: var(--rdp-accent-color); /* The background color of the date when at the start of the selected range. */
    
    --rdp-range_end-background: linear-gradient(var(--rdp-gradient-direction), var(--rdp-range_middle-background-color) 50%, transparent 50%); /* Used for the background of the end of the selected range. */
    --rdp-range_end-color: white;/* The color of the range text. */
    --rdp-range_end-date-background-color: var(--rdp-accent-color); /* The background color of the date when at the end of the selected range. */
    
    --rdp-week_number-border-radius: 100%; /* The border radius of the week number. */
    --rdp-week_number-border: 2px solid transparent; /* The border of the week number. */
    --rdp-week_number-font: 400 small var(--rdp-font-family); /* The font of the week number cells. */
    --rdp-week_number-height: var(--rdp-day-height); /* The height of the week number cells. */
    --rdp-week_number-opacity: 0.75; /* The opacity of the week number. */
    --rdp-week_number-width: var(--rdp-day-width); /* The width of the week number cells. */
    --rdp-weeknumber-text-align: center; /* The text alignment of the weekday cells. */
  
    --rdp-weekday-font: 500 smaller var(--rdp-font-family); /* The font of the weekday. */
    --rdp-weekday-opacity: 0.6; /* The opacity of the weekday. */
    --rdp-weekday-padding: 0.5rem 0rem; /* The padding of the weekday. */
    --rdp-weekday-text-align: center; /* The text alignment of the weekday cells. */
    
    --rdp-main-text-color: #202224;
    --rdp-secondary-text-color: #212121;
  
    --rdp-gradient-direction: 90deg;
  }
  
  .rdp-root[dir="rtl"] {
    --rdp-gradient-direction: -90deg;
  }
  
  /* Root of the component. */
  .rdp-root {
    position: absolute; /* Required to position the navigation toolbar. */
    background-color: white;
    top: 140px;
    box-sizing: border-box;
    z-index: 10;
    padding: 24px 16px;
    border-radius: 8px;
  }
  
  .rdp-root * {
    box-sizing: border-box;
  }
  
  .rdp-day {
    width: var(--rdp-day-width);
    height: var(--rdp-day-height);
    font: var(--rdp-day-font);
    text-align: center;
  }
  
  .rdp-day_button {
    background: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    font: inherit;
    color: var(--rdp-main-text-color);
    justify-content: center;
    align-items: center;
    display: flex;
  
    width: var(--rdp-day_button-width);
    height: var(--rdp-day_button-height);
    border: var(--rdp-day_button-border);
    border-radius: var(--rdp-day_button-border-radius);
  }
  
  .rdp-day_button:disabled {
    cursor: revert;
  }
  
  .rdp-caption_label {
    z-index: 1;
  
    position: relative;
    display: inline-flex;
    align-items: center;
  
    white-space: nowrap;
    border: 0;

    color: var(--rdp-main-text-color);
    font-size: 14px;
    font-weight: 600;
  }
  
  .rdp-button_next,
  .rdp-button_previous {
    border: none;
    background: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    font: inherit;
    color: inherit;
    -moz-appearance: none;
    -webkit-appearance: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    appearance: none;
  
    width: var(--rdp-nav_button-width);
    height: var(--rdp-nav_button-height);
  }
  
  .rdp-button_next:disabled,
  .rdp-button_previous:disabled {
    cursor: revert;
  
    opacity: var(--rdp-nav_button-disabled-opacity);
  }
  
  .rdp-chevron {
    display: inline-block;
    fill: var(--rdp-nav-color);
  }
  
  .rdp-root[dir="rtl"] .rdp-nav .rdp-chevron {
    transform: rotate(180deg);
  }
  
  .rdp-root[dir="rtl"] .rdp-nav .rdp-chevron {
    transform: rotate(180deg);
    transform-origin: 50%;
  }
  
  .rdp-dropdowns {
    position: relative;
    display: inline-flex;
    align-items: center;
    gap: var(--rdp-dropdown-gap);
  }
  .rdp-dropdown {
    z-index: 2;
  
    /* Reset */
    opacity: 0;
    appearance: none;
    position: absolute;
    inset-block-start: 0;
    inset-block-end: 0;
    inset-inline-start: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    cursor: inherit;
    border: none;
    line-height: inherit;
  }
  
  .rdp-dropdown_root {
    position: relative;
    display: inline-flex;
    align-items: center;
  }
  
  .rdp-dropdown_root[data-disabled="true"] .rdp-chevron {
    opacity: var(--rdp-disabled-opacity);
  }
  
  .rdp-month_caption {
    display: flex;
    align-content: center;
    height: var(--rdp-nav-height);
    font: var(--rdp-month_caption-font);
  }
  
  .rdp-months {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    gap: var(--rdp-months-gap);
    max-width: fit-content;
  }
  
  .rdp-month_grid {
    border-collapse: collapse;
  }
  
  .rdp-nav {
    position: absolute;
    inset-block-start: 0;
    inset-inline-end: 0;
  
    display: flex;
    align-items: center;
  
    height: var(--rdp-nav-height);
  }
  
  .rdp-weekday {
    opacity: var(--rdp-weekday-opacity);
    padding: var(--rdp-weekday-padding);
    font: var(--rdp-weekday-font);
    text-align: var(--rdp-weekday-text-align);
    color: var( --rdp-secondary-text-color);
  }
  
  .rdp-week_number {
    opacity: var(--rdp-week_number-opacity);
    font: var(--rdp-week_number-font);
    height: var(--rdp-week_number-height);
    width: var(--rdp-week_number-width);
    border: var(--rdp-week_number-border);
    border-radius: var(--rdp-week_number-border-radius);
    text-align: var(--rdp-weeknumber-text-align);
  }
  
  /* DAY MODIFIERS */
  .rdp-today:not(.rdp-outside) {
    color: var(--rdp-today-color);
  }

  .rdp-selected .rdp-today{
    color: var(--rdp-main-text-color);
  }
  
  .rdp-selected .rdp-day_button {
    border: var(--rdp-selected-border);
    background-color: #edff8c;
  }
  
  .rdp-outside {
    opacity: var(--rdp-outside-opacity);
  }
  
  .rdp-disabled {
    opacity: var(--rdp-disabled-opacity);
  }
  
  .rdp-hidden {
    color: var(--rdp-secondary-text-color);
    opacity: var(--rdp-disabled-opacity);
    
  }
  
  .rdp-range_start {
    background: var(--rdp-range_start-background);
  }
  
  .rdp-range_start .rdp-day_button {
    background-color: var(--rdp-range_start-date-background-color);
    color: var(--rdp-range_start-color);
  }
  
  .rdp-range_middle {
    background-color: var(--rdp-range_middle-background-color);
    font: var(--rdp-range_middle-font);
  }
  
  .rdp-range_middle .rdp-day_button {
    border-color: transparent;
    border: unset;
    border-radius: unset;
    color: var(--rdp-range_middle-color);
  }
  
  .rdp-range_end {
    background: var(--rdp-range_end-background);
    color: var(--rdp-range_end-color);
  }
  
  .rdp-range_end .rdp-day_button {
    color: var(--rdp-range_start-color);
    background-color: var(--rdp-range_end-date-background-color);
  }
  
  .rdp-range_start.rdp-range_end {
    background: revert;
  }
  
  .rdp-focusable {
    cursor: pointer;
  }
  